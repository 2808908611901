import { Row, Col, Divider } from 'antd'
import { IswacContactInfo } from './IswacContactInfo'
import styles from '../Pages.module.css'
import { IswacBoardMember } from './IswacBoardMember';

const boardMembers = [{
    name: 'Rob Provost',
    years: 3
},{
    name: 'Mario Flotte',
    years: 3
},{
    name: 'Dan Keck',
    years: 2
},{
    name: 'Marty Levy',
    years: 2
},{
    name: 'Jim Padilla',
    years: 1
},{
    name: 'Steve Spenser',
    years: 1
}]

export const Contact = (): JSX.Element => {
    return (
        <div className={styles.pagePadding}>
            <div className={styles.titleText}>Contact</div>
            <Row gutter={[16, 16]}>
                <Col sm={24} md={12} lg={6} style={{width: '100%'}}>
                    <IswacContactInfo
                        position='ISWAC Club President'
                        name='Tom Fitzgerald'
                        number='(909) 996-5169'/>
                </Col>
                <Col sm={24} md={12} lg={6} style={{width: '100%'}}>
                    <IswacContactInfo
                        position='ISWAC Vice President'
                        name='Eric Huizar'
                        number='(909) 297-9342'/>
                </Col>
                <Col sm={24} md={12} lg={6} style={{width: '100%'}}>
                    <IswacContactInfo
                        position='ISWAC Club Treasurer/ Trips'
                        name='Steve Berczik'
                        number='(909) 224-0497'/>
                </Col>
                <Col sm={24} md={12} lg={6} style={{width: '100%'}}>
                    <IswacContactInfo
                        position='ISWAC Membership'
                        name='Art Richardson'
                        number=''/>
                </Col>
            </Row>
            <div className={styles.flexRowCenterContactInfoBold}>
                {boardMembers.map((p, i) => (
                    <>
                    {i !== 0 && <Divider type="vertical"/>}
                    <IswacBoardMember
                        key={`boardMember${i}`}
                        name={p.name}
                        years={p.years}/>
                        </>
                ))}
            </div>
        </div>
    )
}